<template>
  <div class="page-container">
    <navBar title="搜索"></navBar>
    <van-search
      v-model="searchValue"
      placeholder="请输入搜索关键词"
      shape="round"
      @input="inputHandle(searchValue)"
    />

    <div class="hot-words-wrapper" v-if="!searchValue || resultList.length === 0">
      <div class="title">热门搜索</div>
      <div class="hot-words-list">
        <div class="hot-item" @click="selectHotWord(item.name)" v-for="item in hotWords" :key="item.id">{{item.name}}
        </div>
      </div>
    </div>

    <div class="hot-words-wrapper" v-else>
      <div class="hot-item" @click="selectResultItem(item.id)" v-for="item in resultList" :key="item.id">
        {{item.name}}
      </div>
    </div>
  </div>
</template>

<script>
  import navBar from 'components/common/navBar/navBar'
  import { searchBrandList, getHotWord } from 'api/api'

  export default {
    name: 'search',
    data () {
      return {
        searchValue: '',
        hotWords: [],
        resultList: []
      }
    },
    components:{
      navBar
    },
    methods: {
      inputHandle (val) {
        this.search(val)
      },
      search (val) {
        let params = {
          name: val
        }
        searchBrandList(params).then(res => {
          this.resultList = res
        })
      },
      selectHotWord (val) {
        this.searchValue = val
        this.search(val)
      },
      selectResultItem (id) {
        this.$router.push(`/evaluation?id=${id}`)
      },
      getHotWord () {
        getHotWord().then(res => {
          this.hotWords = res
        })
      }
    },
    created () {
      this.getHotWord()
    }
  }
</script>

<style lang="less" scoped>
  .page-container {
    min-height: 100vh;
    background-color: #fff;
  }

  .hot-words-wrapper {
    padding: 0.1rem 0.3rem 0.3rem;

    .title {
      margin-bottom: 0.3rem;
      font-size: 0.3rem;
      padding-left: 0.1rem;
    }

    .hot-item {
      color: #a3a3a3;
      height: 0.8rem;
      line-height: 0.8rem;
      padding-left: 0.3rem;
      border-bottom: 1px solid #e5e5e5;
    }

  }
</style>
